import { useEffect, useState } from "react";
import { getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import {
  createDivision,
  deleteDivisionbyId,
  getDivisions,
  updateDivision,
} from "../endpoints/all/division";

const key = "user-admin-values";
const useDivision = () => {
  const keyValues = getLocalStorageWithExpiration(key);
  const [divisionList, setDivisionList] = useState([]);
  const [loading, setLoading] = useState({
    isCreatingDivision: false,
    isFetchingDivisions: false,
  });

  const getDivisionsList = async () => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isFetchingDivisions: true }));

    try {
      const { data, status } = await getDivisions(token);

      if (data.status || status === 200) {
        const divisions = data?.data?.allDivision?.map((i) => {
          return { label: i.name, value: i.id };
        });

        setDivisionList(divisions);
        setLoading((prev) => ({ ...prev, isFetchingDivisions: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingDivisions: false }));
    }
  };

  const createDivisionFn = async ({ name }) => {
    const { token } = keyValues;
    const field = {
      name,
    };
    setLoading((prev) => ({ ...prev, isCreatingDivision: true }));

    try {
      const { data, status } = await createDivision(token, field);
      if (status === 201 || data.status) {
        toast.success(data?.message);
        getDivisionsList();
        setLoading((prev) => ({ ...prev, isCreatingDivision: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isCreatingDivision: false }));
      toast.error("Failed to Create Division");
    }
  };

  const updateDivisionFn = async ({ name, id }) => {
    const { token } = keyValues;
    const field = {
      name,
      id,
      _method: "PUT",
    };
    setLoading((prev) => ({ ...prev, isFetchingDivisions: true }));

    try {
      const { data, status } = await updateDivision(token, field);
      if (status === 202 || data.status) {
        toast.success(data?.message);
        getDivisionsList();
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingDivisions: false }));
      toast.error("Failed to Update Division");
    }
  };

  const deleteDivision = async ({ id }) => {
    const { token } = keyValues;
    try {
      const { data, status } = await deleteDivisionbyId(token, id);
      if (status === 202 || data.status === true) {
        toast.success(data?.message);
        getDivisionsList();
      }
    } catch (error) {
      toast.error("Failed to delete state");
    }
  };

  useEffect(() => {
    getDivisionsList();
  }, [keyValues?.token]);

  return {
    loading,
    divisionList,
    createDivisionFn,
    deleteDivision,
    updateDivisionFn,
  };
};

export default useDivision;
