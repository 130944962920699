import { useEffect, useState } from "react";
import {
  createCity,
  createState,
  deleteCitybyId,
  deleteStatebyId,
  getCitiesbyStateId,
  getStates,
  updateCity,
  updateState,
} from "../endpoints/all/location";
import { getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";

const key = "user-admin-values";
const useFixedLocation = () => {
  const keyValues = getLocalStorageWithExpiration(key);
  const [statesList, setStatesList] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState({
    isCreatingState: false,
    isFetchingState: false,
    isCreatingCity: false,
    isFetchingCity: false,
  });

  const getStateList = async () => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isFetchingState: true }));

    try {
      const { data, status } = await getStates(token);

      if (data.status || status === 200) {
        const states = data?.data?.allState?.map((i) => {
          return { label: i.name, value: i.id };
        });

        setStatesList(states);
        setLoading((prev) => ({ ...prev, isFetchingState: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingState: false }));
    }
  };

  const getCitiesByStateId = async ({ id }) => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isFetchingCity: true }));
    try {
      const { data, status } = await getCitiesbyStateId(token, id);
      if (status === 200 || data.status) {
        setCities(data?.cities);
        setLoading((prev) => ({ ...prev, isFetchingCity: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingCity: false }));
      toast.error("Failed to Fetch Cities");
    }
  };

  const createStateFn = async ({ name }) => {
    const { token } = keyValues;
    const field = {
      name,
    };
    setLoading((prev) => ({ ...prev, isCreatingState: true }));

    try {
      const { data, status } = await createState(token, field);
      if (status === 201 || data.status === true) {
        toast.success(data?.message);
        getStateList();
        setLoading((prev) => ({ ...prev, isCreatingState: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isCreatingState: false }));
      toast.error("Failed");
    }
  };

  const createCityFn = async ({ name, state_id }) => {
    const { token } = keyValues;
    const field = {
      name,
      state_id,
    };
    setLoading((prev) => ({ ...prev, isCreatingCity: true }));

    try {
      const { data, status } = await createCity(token, field);
      if (status === 201 || data.status === true) {
        toast.success(data?.message);
        getCitiesByStateId({ id: state_id });
        setLoading((prev) => ({ ...prev, isCreatingCity: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isCreatingCity: false }));
      toast.error("Failed");
    }
  };

  const updateStateFn = async ({ name, id }) => {
    const { token } = keyValues;
    const field = {
      name,
      id,
      _method: "PUT",
    };
    setLoading((prev) => ({ ...prev, isFetchingState: true }));

    try {
      const { data, status } = await updateState(token, field);
      if (status === 202 || data.status) {
        toast.success(data?.message);
        getStateList();
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingState: false }));
      toast.error("Failed to Update State");
    }
  };

  const updateCityFn = async ({ name, id, state_id }) => {
    const { token } = keyValues;
    const field = {
      name,
      state_id,
      id,
      _method: "PUT",
    };
    setLoading((prev) => ({ ...prev, isFetchingCity: true }));
    try {
      const { data, status } = await updateCity(token, field);
      if (status === 202 || data.status) {
        toast.success(data?.message);
        getCitiesByStateId({ id: state_id });
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingCity: false }));
      toast.error("Failed to Update City");
    }
  };

  const deleteCity = async ({ id, state_id }) => {
    const { token } = keyValues;
    try {
      const { data, status } = await deleteCitybyId(token, id);
      if (status === 202 || data.status === true) {
        toast.success(data?.message);
        getCitiesByStateId({ id: state_id });
      }
    } catch (error) {
      toast.error("Failed to delete state");
    }
  };

  const deleteState = async ({ id }) => {
    const { token } = keyValues;
    try {
      const { data, status } = await deleteStatebyId(token, id);
      if (status === 202 || data.status === true) {
        toast.success(data?.message);
        getStateList();
      }
    } catch (error) {
      toast.error("Failed to delete state");
    }
  };

  useEffect(() => {
    getStateList();
  }, [keyValues?.token]);

  return {
    loading,
    statesList,
    createStateFn,
    getCitiesByStateId,
    cities,
    createCityFn,
    deleteState,
    deleteCity,
    updateStateFn,
    updateCityFn,
  };
};

export default useFixedLocation;
