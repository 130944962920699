import { useCallback } from "react";
import { updateUserStatus } from "../endpoints/singleUser";
import {
  errorProps,
  getLocalStorageWithExpiration,
  successProps,
} from "../utils";
import { toast } from "react-toastify";

const key = "user-admin-values";
const useStatusUpdate = ({ navigate }) => {
  const keyValues = getLocalStorageWithExpiration(key);
  const updateUserStatusPost = useCallback(async (values) => {
    const { id, acc_type, status, message } = values;
    const { token } = keyValues;
    const fields = {
      status,
      status_message: message,
      _method: "PATCH",
    };
    try {
      const { data, status } = await updateUserStatus(
        token,
        id,
        acc_type,
        fields
      );
      if (status === 202 || data.status === true) {
        toast.success(data?.message, successProps);
        navigate(-1);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error(error.message, errorProps);
      }
    }
  }, []);

  return { updateUserStatusPost };
};

export default useStatusUpdate;
