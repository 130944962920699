import { config } from "../../config";
import axios from "axios";

export const getDivisions = async (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(`${config.testApiBaseUrl}/admin/view-divisions`);
  return { data: res.data, status: res.status };
};

export const createDivision = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/add-divisions`,
    field
  );
  return { data: res.data, status: res.status };
};

export const updateDivision = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-divisions/${field.id}`,
    field
  );
  return { data: res.data, status: res.status };
};

export const deleteDivisionbyId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.delete(
    `${config.testApiBaseUrl}/admin/delete-divisions/${id}`
  );
  return { data: res.data, status: res.status };
};
