import React, { useEffect, useState } from "react";
import "./Testimonials.css";
import CustomButton from "../../../../components/customButton/CustomButton";
import useTestimonial from "../../../../hooks/useTestimonial";
import { RiAddFill } from "react-icons/ri";
import CustomDialog from "../../../../components/customDialog/CustomDialog";
import FormTestimony from "./form/FormTestimony";
import { config } from "../../../../config";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";

const Testimonials = () => {
  const [toggle, setToggle] = useState(false);
  const [img, setImg] = useState(null);
  const {
    values,
    handleChange,
    handleSubmit,
    getTestimonies,
    testimonies,
    loading,
    onDelete,
    onEdit,
  } = useTestimonial({
    onClose: () => {
      setToggle(false);
      onEdit(null);
    },
  });

  useEffect(() => {
    getTestimonies();
  }, [loading.isSubmitting]);

  return (
    <div className="test-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>Testimonials</h3>

        <CustomButton
          customStyles={{
            width: "30px",
            height: "30px",
            marginTop: "0",
            borderRadius: "3px",
          }}
          onClick={() => setToggle(!toggle)}
          children={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <RiAddFill size={20} />
            </div>
          }
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "2rem",
        }}
      >
        {loading?.isFetchingData ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Loading...
          </div>
        ) : (
          testimonies?.map((test) => {
            const sourceImg = `${config.getPublicImages}/${test?.image}`;
            return (
              <div className="card-test" key={test.id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {sourceImg && (
                      <img
                        src={sourceImg}
                        alt="profile"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          border: "1px solid gray",
                          objectFit: "cover",
                        }}
                      />
                    )}

                    <div>
                      <h5
                        style={{
                          fontSize: "1rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {test.name}
                      </h5>
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        {test.position}
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <RiDeleteBin6Line
                      color="red"
                      style={{ cursor: "pointer", fontSize: "1.5rem" }}
                      onClick={() => onDelete(test.id)}
                    />

                    <FiEdit
                      color="blue"
                      style={{ cursor: "pointer", fontSize: "1.5rem" }}
                      onClick={() => {
                        setToggle(true);
                        onEdit({
                          id: test.id,
                          name: test.name,
                          company_name: test.company_name,
                          position: test.position,
                          website: test.website,
                          testimony: test.testimony,
                        });
                        setImg(test.image);
                      }}
                    />
                  </div>
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <p>{test.testimony}</p>
                </div>
              </div>
            );
          })
        )}

        {!loading?.isFetchingData && !testimonies.length && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            No Data
          </div>
        )}
      </div>

      <CustomDialog
        onClose={() => {
          setToggle(false);
          onEdit(null);
        }}
        open={toggle}
      >
        <FormTestimony
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading?.isSubmitting}
          img={img}
        />
      </CustomDialog>
    </div>
  );
};

export default Testimonials;
