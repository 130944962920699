import React, { memo, useEffect, useState } from "react";
import logo from "../../assets/logo-profile.png";
import { BsArrowRight } from "react-icons/bs";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./DashboardDrawer.css";
import useLogout from "../../hooks/useLogout";
import { Collapse } from "@mui/material";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const DashboardDrawer = ({ navList }) => {
  const logout = useLogout();
  const location = useLocation();
  const applicantsPath = location.pathname.includes("/applicants");
  const user = useTypedSelector((store) => store.user);

  // useEffect(() => {
  //   if (applicantsPath) setToggle(true);
  // }, [applicantsPath]);

  return (
    <div className="dashboard-Drawer-container">
      <div className="profile-left">
        <div className="upper">
          <Link to="/login" className="desktop">
            <div className="logo-img">
              <img src={logo} alt="" />
            </div>
          </Link>

          <div className="home-box-one-button desktop">
            <button>
              Find Jobs <BsArrowRight />
            </button>
          </div>

          <div className="dashboard-options">
            <div className="box-1">
              {navList?.map((nav) => (
                <NavLink
                  key={nav.id}
                  to={nav.to ? nav.to : null}
                  className={(navClass) =>
                    navClass.isActive && nav.to
                      ? "profile-active"
                      : "profile-dom"
                  }
                  onClick={() => {
                    if (nav.name === "Logout") logout();
                    if (nav.name === "Applicants" || nav.name === "Settings") {
                      nav.toggle = !nav.toggle;
                    }
                  }}
                >
                  <div>
                    <div
                      className={`profile-button ${
                        nav.name === "Applicants" && applicantsPath
                          ? "active-tab-bg"
                          : ""
                      }`}
                      onClick={() => {}}
                    >
                      <div className="main-item">
                        {nav.icon}
                        <span>{nav.name}</span>
                      </div>

                      {!!nav?.child &&
                        (nav.toggle ? <IoIosArrowDown /> : <IoIosArrowUp />)}
                    </div>

                    {!!nav?.child &&
                      nav.child.map((childNav) => (
                        <Collapse in={nav.toggle} key={childNav.id}>
                          <div className="collapse-bg">
                            <NavLink
                              to={childNav.link}
                              className={(navClass) =>
                                navClass.isActive
                                  ? "profile-active"
                                  : "profile-dom"
                              }
                            >
                              <div className={`profile-button`}>
                                <div className="main-item">
                                  {childNav.icon}
                                  <span>{childNav.label}</span>
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </Collapse>
                      ))}
                  </div>
                </NavLink>
              ))}
            </div>
          </div>
        </div>

        <div className="home-box-one-button mobile">
          <button>
            Find Jobs <BsArrowRight />
          </button>
        </div>

        <div className="box-2">
          <div className="first">
            <div className="profile-pics" />

            <div className="box-2-text">
              <span>{user?.name}</span>
              <h5>Admin</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardDrawer);
