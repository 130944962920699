import { useFormik } from "formik";
import { useState } from "react";
import {
  addTestimony,
  deleteTestimonybyId,
  testimoniesCall,
  updateTestimony,
} from "../endpoints/all/testimonials";
import { getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";

const initial = {
  id: null,
  name: "",
  // company_name: "",
  position: "",
  // website: "",
  testimony: "",
  image: null,
};

const useTestimonial = ({ onClose }) => {
  const key = "user-admin-values";
  const keyValues = getLocalStorageWithExpiration(key);
  const [initialValues, setInitialValues] = useState(initial);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState({
    isSubmitting: false,
    isFetchingData: false,
  });

  const onSubmit = async (values, { resetForm }) => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isSubmitting: true }));

    const valuesUpdate = {
      ...values,
      _method: "PUT",
    };

    try {
      const { data, status } = values.id
        ? await updateTestimony(token, valuesUpdate)
        : await addTestimony(token, values);
      console.log(data, status);
      if (status === 200 || data.status) {
        toast.success(data.message);
        setLoading((prev) => ({ ...prev, isSubmitting: false }));
        resetForm();
        onClose();
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isSubmitting: false }));
      console.log(error);
      toast.error("fill all fields.");
    }
  };

  const getTestimonies = async () => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isFetchingData: true }));
    try {
      const { data, status } = await testimoniesCall(token);
      if (status === 200 || data.status) {
        setData(data.data.all);
        setLoading((prev) => ({ ...prev, isFetchingData: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingData: false }));
    }
  };

  const onDelete = async (id) => {
    const { token } = keyValues;
    try {
      const { data, status } = await deleteTestimonybyId(token, id);
      if (status === 200 || data.status) {
        getTestimonies();
        toast.success(data.message);
      }
    } catch (error) {}
  };

  const onEdit = (values) => {
    if (values)
      setInitialValues({
        id: values.id || null,
        name: values.name || "",
        // company_name: values.company_name || "",
        position: values.position || "",
        // website: values.website || "",
        testimony: values.testimony || "",
        image: null,
      });

    if (!values) setInitialValues(initial);
  };

  const formik = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values, others) => {
      onSubmit(values, others);
    },
  });

  return {
    ...formik,
    onEdit,
    getTestimonies,
    testimonies: data,
    loading,
    onDelete,
  };
};

export default useTestimonial;
