import { useEffect, useState } from "react";
import { getLocalStorageWithExpiration } from "../utils";
import { toast } from "react-toastify";
import {
  createSkill,
  deleteSkillbyId,
  getSkills,
  updateSkill,
} from "../endpoints/all/skills";

const key = "user-admin-values";
const useSkill = () => {
  const keyValues = getLocalStorageWithExpiration(key);
  const [skillList, setSkillList] = useState([]);
  const [loading, setLoading] = useState({
    isCreatingSkill: false,
    isFetchingSkills: false,
  });

  const getSkillList = async () => {
    const { token } = keyValues;
    setLoading((prev) => ({ ...prev, isFetchingSkills: true }));

    try {
      const { data, status } = await getSkills(token);

      if (data.status || status === 200) {
        const skills = data?.data?.allSkill?.map((i) => {
          return { label: i.name, value: i.id };
        });

        setSkillList(skills);
        setLoading((prev) => ({ ...prev, isFetchingSkills: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingSkills: false }));
    }
  };

  const createSkillFn = async ({ name }) => {
    const { token } = keyValues;
    const field = {
      name,
    };
    setLoading((prev) => ({ ...prev, isCreatingSkill: true }));

    try {
      const { data, status } = await createSkill(token, field);
      if (status === 201 || data.status) {
        toast.success(data?.message);
        getSkillList();
        setLoading((prev) => ({ ...prev, isCreatingSkill: false }));
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isCreatingSkill: false }));
      toast.error("Failed to Create Skill");
    }
  };

  const updateSkillFn = async ({ name, id }) => {
    const { token } = keyValues;
    const field = {
      name,
      id,
      _method: "PUT",
    };
    setLoading((prev) => ({ ...prev, isFetchingSkills: true }));

    try {
      const { data, status } = await updateSkill(token, field);
      if (status === 202 || data.status) {
        toast.success(data?.message);
        getSkillList();
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, isFetchingSkills: false }));
      toast.error("Failed to Update Skill");
    }
  };

  const deleteSkill = async ({ id }) => {
    const { token } = keyValues;
    try {
      const { data, status } = await deleteSkillbyId(token, id);
      if (status === 202 || data.status === true) {
        toast.success(data?.message);
        getSkillList();
      }
    } catch (error) {
      toast.error("Failed to Delete Skill");
    }
  };

  useEffect(() => {
    getSkillList();
  }, [keyValues?.token]);

  return {
    loading,
    skillList,
    createSkillFn,
    deleteSkill,
    updateSkillFn,
  };
};

export default useSkill;
