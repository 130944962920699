import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  errorProps,
  getLocalStorageWithExpiration,
  setLocalStorageWithExpiration,
  successProps,
} from "../utils/index";
import { getAdminUsers, signIn } from "../endpoints/auth";
import { useActions } from "./useActions";
import { useState } from "react";

const key = "user-admin-values";
const expirationHours = 24;

const useLogin = () => {
  const navigate = useNavigate();
  const actions = useActions();
  const [loading, setLoading] = useState(false);

  const getUserDetails = async (values) => {
    const { token, acc_email } = values;

    try {
      const { data, status } = await getAdminUsers(token);
      if (status === 201 || status === 200 || data?.users) {
        const filterAdminUser = data?.users.filter(
          (user) => user.email === acc_email
        );
        if (filterAdminUser) {
          actions.setUser(...filterAdminUser);
          navigate("/backend");
        }
        setLoading(false);
      }
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error(error.message, errorProps);
      } else {
        toast.error("Can not get admin users", errorProps);
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const { data, status } = await signIn(values);
    console.log(data, status)
    if (status === 200 && data.status === true) {
      setLoading(false);
      if (data?.account_data?.role === "1") {
        toast.success("Successfully Login", successProps);
        const values = {
          token: data?.token,
          acc_email: data?.account_data?.email,
        };
        setLocalStorageWithExpiration(key, values, expirationHours);
        const keyValues = getLocalStorageWithExpiration(key);
        if (keyValues) getUserDetails(keyValues);
      } else {
        setLoading(false);
        toast.error("Only admin can access", errorProps);
      }
    } else if (!data.status) {
      setLoading(false);
      if (status === 401) {
        toast.error(data.message, errorProps);
      } else if (data.message === "Network Error") {
        toast.error(data.message, errorProps);
      } else {
        toast.error("Login failed", errorProps);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
      password: Yup.string()
        .min(6, "Password must be at least 8 characters")
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
        //   "Password must contain at least one lowercase letter, one uppercase letter, and one number"
        // )
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return { ...formik, loading };
};

export default useLogin;
