import React, { useEffect, useState } from "react";
import "./Location.css";
import { toast } from "react-toastify";
import { GrFormEdit } from "react-icons/gr";
import useFixedLocation from "../../../../../hooks/useFixedLocation";
import CustomInput from "../../../../../components/customInput/CustomInput";
import CustomButton from "../../../../../components/customButton/CustomButton";
import CustomDialog from "../../../../../components/customDialog/CustomDialog";
import CustomAutoComplete from "../../../../../components/customAutoComplete/CustomAutoComplete";

const Location = () => {
  const [values, setValues] = useState({
    state: "",
    city: "",
    states: [],
    state_id: null,
    city_id: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const {
    loading,
    statesList,
    createStateFn,
    getCitiesByStateId,
    cities,
    createCityFn,
    deleteState,
    deleteCity,
    updateStateFn,
    updateCityFn,
  } = useFixedLocation();

  useEffect(() => {
    setValues((prev) => ({ ...prev, states: statesList }));
  }, [statesList]);

  return (
    <div className="location-settings">
      <div className="loc-top">
        <div className="loc-input-bg">
          <div className="loc-input">
            <CustomInput
              id="state"
              label="State"
              placeholder="Enter State Name"
              type="text"
              value={values.state}
              onChange={(e) => setValues((prev) => ({ ...prev, state: e }))}
              inputStyles={{
                height: "40px",
                borderRadius: "3px",
              }}
              labelStyle={{
                fontSize: "18px",
                fontWeight: "600",
              }}
            />
          </div>

          <div className="loc-btn">
            <CustomButton
              onClick={() => {
                if (values.state.length > 0 && values.state_id === null) {
                  createStateFn({ name: values.state });
                  setValues((prev) => ({ ...prev, state: "", state_id: null }));
                } else if (
                  values.state.length > 0 &&
                  values.state_id !== null
                ) {
                  updateStateFn({ name: values.state, id: values.state_id });
                  setValues((prev) => ({ ...prev, state: "", state_id: null }));
                } else {
                  toast.warning("Add State");
                }
              }}
              title={values.state_id === null ? "Submit" : "Update"}
              type="btn"
              loading={loading.isCreatingState}
              customStyles={{
                width: "100px",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
                cursor: "pointer",
                height: "40px",
              }}
            />
          </div>
        </div>
        <div className="loc-btn">
          <CustomButton
            onClick={() => {
              if (values.states?.length === 0) {
                toast.warning("Add State First");
              } else {
                setIsOpen(true);
              }
            }}
            title="Add City"
            type="btn"
            loading={false}
            customStyles={{
              width: "100px",
              borderRadius: "5px",
              backgroundColor: "#245293",
              color: "#fff",
              cursor: "pointer",
              height: "40px",
            }}
          />
        </div>
      </div>

      <div className="loc-content">
        <h5>List of State Created</h5>

        <div className="loc-states">
          {values.states?.length === 0 && loading.isFetchingState ? (
            <div className="loc-empty">
              <p>loading...</p>
            </div>
          ) : values.states?.length === 0 && !loading.isFetchingState ? (
            <div className="loc-empty">No states added, Add states</div>
          ) : (
            values.states?.map((i) => (
              <div key={i.value} className="loc-states-each">
                <span
                  onClick={() => {
                    setValues((prev) => ({
                      ...prev,
                      state: i.label,
                      state_id: i.value,
                    }));
                  }}
                >
                  <GrFormEdit />
                </span>
                <div className="main">{i.label}</div>
                <span onClick={() => deleteState({ id: i.value })}>x</span>
              </div>
            ))
          )}
        </div>
      </div>

      <CustomDialog
        onClose={() => {
          setIsOpen(false);
          setValues((prev) => ({ ...prev, state: "", state_id: null }));
        }}
        open={isOpen}
      >
        <div className="modal-division-bg">
          <div className="modal-division-bg-header">
            <h4>Add City</h4>
          </div>

          <div className="modal-division-bg-body">
            <div className="auto-complete-con">
              <label className="division-label">State</label>
              <CustomAutoComplete
                id="state"
                isClearable={false}
                placeholder={"Select State"}
                options={values.states}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "5px",
                    border: "1px solid #000",
                  },
                }}
                onChange={(e) => {
                  setValues((prev) => ({ ...prev, state_id: e.value }));
                  getCitiesByStateId({ id: e.value });
                }}
              />
            </div>
            <div className="auto-complete-con">
              <CustomInput
                id="city"
                label="City"
                placeholder="Enter City Name"
                type="text"
                value={values.city}
                onChange={(e) => setValues((prev) => ({ ...prev, city: e }))}
                inputStyles={{
                  height: "50px",
                  borderRadius: "5px",
                  border: "1px solid #000",
                }}
                labelStyle={{
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              />
            </div>

            <div className="loc-content">
              <h5>List of Cities Created In Selected State</h5>

              <div className="loc-states">
                {cities?.length === 0 && loading.isFetchingCity ? (
                  <div className="loc-empty">
                    <p>loading...</p>
                  </div>
                ) : cities?.length === 0 && !loading.isFetchingCity ? (
                  <div className="loc-empty">
                    No city added for this State, Add cities
                  </div>
                ) : (
                  cities?.map(({ name, id }) => (
                    <div key={name} className="loc-states-each">
                      <span
                        onClick={() => {
                          setValues((prev) => ({
                            ...prev,
                            city: name,
                            city_id: id,
                          }));
                        }}
                      >
                        <GrFormEdit />
                      </span>
                      <div className="main">{name}</div>
                      <span
                        onClick={() =>
                          deleteCity({ id: id, state_id: values.state_id })
                        }
                      >
                        x
                      </span>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="modal-division-bg-footer">
            <CustomButton
              title={values.city_id === null ? "Submit" : "Update"}
              type="btn"
              loading={loading.isCreatingCity}
              customStyles={{ width: "30%", borderRadius: "5px" }}
              onClick={() => {
                if (values.city.length > 0 && values.city_id === null) {
                  createCityFn({
                    name: values.city,
                    state_id: values.state_id,
                  });
                  setValues((prev) => ({ ...prev, city: "", city_id: null }));
                } else if (values.city.length > 0 && values.city_id !== null) {
                  updateCityFn({
                    name: values.city,
                    id: values.city_id,
                    state_id: values.state_id,
                  });
                  setValues((prev) => ({ ...prev, city: "", city_id: null }));
                } else {
                  toast.warning("Add Skill");
                }
              }}
            />
          </div>
        </div>
      </CustomDialog>
    </div>
  );
};

export default Location;
