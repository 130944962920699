import React, { useCallback, useState } from "react";
import "./FormTestimony.css";
import CustomButton from "../../../../../components/customButton/CustomButton";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { errorProps } from "../../../../../utils";
import { config } from "../../../../../config";
import CustomInput from "../../../../../components/customInput/CustomInput";
import { CgProfile } from "react-icons/cg";

const FormTestimony = ({
  values,
  handleChange,
  handleSubmit,
  loading,
  img,
}) => {
  const [image, setImage] = useState(null);

  const handleUpload = useCallback(
    (files) => {
      const file = files[0];
      const fileSizeInKB = file?.size / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      const sizeAllowed = 1 >= fileSizeInMB;
      const isAllow = file.type === "image/jpeg" || file.type === "image/png";

      if (isAllow) {
        if (sizeAllowed) {
          setImage(
            ...files.map((file) => {
              handleChange({
                target: {
                  name: "image",
                  value: Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  }),
                },
              });
              return Object.assign(file, {
                preview: URL.createObjectURL(file),
              });
            })
          );
        } else {
          toast.error("Image should be not be more than 1MB", errorProps);
        }
      } else {
        toast.error("Image should be in PNG or JPEG", errorProps);
      }
    },
    [handleChange]
  );

  const { getRootProps: getRootImage, getInputProps: getInputImage } =
    useDropzone({ onDrop: handleUpload });

  const styles = {
    input: {
      marginTop: "0.5rem",
      width: "100%",
      padding: "0.5rem",
      borderRadius: "3px",
      border: "1px solid rgb(236, 236, 236)",
      paddingLeft: "15px",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 500,
      color: "black",
      outline: "none",
    },
  };

  const displayImg = image?.preview || img;
  const sourceImg = image?.preview || `${config.getPublicImages}/${img}`;

  return (
    <div className="modal-test-bg">
      <div className="upload-img-bg">
        {displayImg ? (
          <img src={sourceImg} alt="profile-pics" />
        ) : (
          <img
            src={
              "https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
            }
            alt="profile"
          />
        )}
        <div>
          <input type="files" {...getInputImage()} />
          <CustomButton
            {...getRootImage()}
            customStyles={{
              width: "140px",
              height: "35px",
              marginTop: "0",
              fontSize: "14px",
            }}
            children={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <span>Upload Image</span>
                <CgProfile />
              </div>
            }
          />
        </div>
      </div>
      <div className="test-form">
        <div className="test-input">
          <CustomInput
            id="name"
            label="Name"
            placeholder="Enter Name"
            type="text"
            value={values.name}
            onChange={handleChange("name")}
            inputStyles={{
              height: "40px",
              borderRadius: "3px",
            }}
            labelStyle={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          />
        </div>

        <div className="test-input">
          <CustomInput
            id="position_name"
            label="Division"
            placeholder="Enter Position"
            type="text"
            value={values.position}
            onChange={handleChange("position")}
            inputStyles={{
              height: "40px",
              borderRadius: "3px",
            }}
            labelStyle={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          />
        </div>
      </div>
      <div>
        <h4
          style={{
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          Testimony
        </h4>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <textarea
            style={styles.input}
            cols="20"
            rows="5"
            placeholder="Write a Testimony..."
            value={values.testimony}
            onChange={handleChange("testimony")}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <span>minimun of 100 char</span>
            <span
              style={{
                color:
                  values.testimony.length < 100 || values.testimony.length > 200
                    ? "red"
                    : "#000",
              }}
            >
              {values.testimony.length} / 200
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <CustomButton
          title={values.id === null ? "Submit" : "Update"}
          onClick={handleSubmit}
          loading={loading}
          disable={
            loading ||
            values.testimony.length < 100 ||
            values.testimony.length > 200
          }
          type="submit"
          customStyles={{
            width: "80px",
            borderRadius: "5px",
            backgroundColor: "#245293",
            color: "#fff",
            cursor: "pointer",
            height: "35px",
            fontSize: "14px",
          }}
        />
      </div>
    </div>
  );
};

export default FormTestimony;
