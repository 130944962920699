import { config } from "../../config";
import axios from "axios";

export const addTestimony = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/add-testimonial`,
    field,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return { data: res.data, status: res.status };
};

export const testimoniesCall = async (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.get(
    `${config.testApiBaseUrl}/admin/view-testimonial`
  );
  return { data: res.data, status: res.status };
};

export const deleteTestimonybyId = async (token, id) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.delete(
    `${config.testApiBaseUrl}/admin/delete-testimonial/${id}`
  );
  return { data: res.data, status: res.status };
};

export const updateTestimony = async (token, field) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  const res = await axios.post(
    `${config.testApiBaseUrl}/admin/update-testimonial/${field?.id}`,
    field,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return { data: res.data, status: res.status };
};
